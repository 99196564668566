import React from 'react';
import PropTypes from 'prop-types';
import BankSlipCopyPage from '../../containers/BankSlipCopyPage';

const BankSlipCopy = ({ location, pageContext }) => {
  return <BankSlipCopyPage location={location} pageContext={pageContext} />;
};

BankSlipCopy.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BankSlipCopy;
